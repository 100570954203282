<template>
  <div class="feiyiItem">
    <van-nav-bar
      left-text="返回"
      :title="detailLists.intangibleCulturalHeritageName"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="box" v-html="detailLists.introduce"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "feiyiItem",
  data() {
    return {};
  },
  computed: {
    ...mapState(["detailLists"]),
  },
  created() {
    this.addUserReadNumber();
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    // 添加用户阅读量
    addUserReadNumber() {
      // let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      // if (userId == null) {
      //   return false;
      // }
      let id = this.detailLists.id;
      let params = new FormData();
      // params.append("userId", userId);
      params.append("id", id);
      this.$axios
        .post("/api/ich/addTrIntangibleCulturalHeritageReadNumber", params)
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.feiyiItem {
  padding-top: 0.9rem;
  margin-bottom: 7.5vh;
}
/** 导航栏 */
/deep/ .van-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
  .van-nav-bar__text {
    color: #222;
  }
  .van-nav-bar__title {
    padding: 0.2rem 0;
    font-weight: 500;
    font-size: 0.34rem;
    color: #222;
  }
}
.box {
  padding: 0.4rem 0.2rem;
  max-width: 100vw !important;
  /deep/ img {
    width: 100% !important;
  }
  /deep/ table {
    width: 100%;
  }
}

// p {
//   font-size: 0.3rem;
//   color: #000;
//   margin: 0.2rem 0;
// }
// /** 标题 */
// h1 {
//   font-size: 0.38rem;
//   color: #774545;
// }
// h2 {
//   font-size: 0.34rem;
//   color: #000;
// }
// /** 图片 */
// img {
//   width: 100%;
// }
// /** 介绍 */
// .texts {
//   p {
//     text-indent: 2em;
//   }
// }
// table {
//   border-collapse: collapse;
//   margin-bottom: 0.3rem;
//   th,
//   td {
//     border: 1px solid #000;
//     text-align: center;
//     padding: 0.1rem 0;
//   }
//   p {
//     font-size: 0.24rem;
//     margin: 0;
//   }
// }
</style>